.main_div {
    background: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.from_data {
    background: #ffffff;
    box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.15);
    position: absolute;
    width: 50%;
    left: 48%;
    top: 50%;
    padding: 3rem;
    transform: translate(-50%, -50%);
}

.headinglogin {
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-weight: bold;
    font-size: 22px;
    color: #6b6a69;
    line-height: 28px;
}

.lablesection {
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #94A0B4;
}

.loginbtn {
    background: #05395a;
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 23px;
}

.emailinput {
    border: 1px solid #e9e9e9;
    background-color: #e9e9e9;
    outline: none;
    margin-left: 4px;
    font-size: 18px;
    padding-left: 40px !important;
}

.showpassicon {
    left: 95%;
    top: -10%;
}
.showpassiconchage{
     left: 95%;
     top: 10%;
}

.Forgetpassword {
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #9D0400;
}

.sinbtn {
    color: #c4ac26;

}

.passcharacters {
    color: #94A0B4;
    font-size: 14px;
    line-height: 15px;
}

.registerbtn {
    font-size: 18px;
    font-style: normal;
    color: #6b6a69;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.Registersingup {
    color: #b93732;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
}

.LockOutlinedicon {
    margin-top: 35px;
    /* color: #05395a; */
    font-weight: bolder;
    padding: 0px 5px;
    margin-left: 3px;
}

.emailicon {
    margin-top: 25px;
    margin-left: 3px;
    /* color: #05395a; */
    font-weight: bolder;
    padding: 0px 5px;
}

@media only screen and (max-width: 425px) {
    .from_data {
        background: #d8d8d8;
        box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.15);
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        padding: 3rem;
        transform: translate(-50%, -50%);
    }

    .showpassicon {
        left: 100%;
        top: -3%;
    }
    .showpassiconchage{
         left: 100%;
         top: 3%;
    }

    .headinglogin {
        font-size: 12px;
        line-height: 15px;
    }

    .Forgetpassword {
        font-size: 12px;
        line-height: 15px;
    }

    .LockOutlinedicon {
        margin-top: 45px;
    }

    .emailicon {
        margin-top: 35px;
    }
    .emailinput {
        padding-left: 24px !important;
    }
}
